/* .research-map-area {

} */

.research-map-container {
  height: 600px;
  margin-top: -5rem;
}

/*
  Reset button styles
  It takes a little bit of work to achieve a “blank slate” look.
*/
/* Cant have basic tage style in this level, Needs fix*/
button {
  border: none;
  font: inherit;
  color: white;
  background-color: transparent;
  cursor: pointer;
}

.marker {
  background-image: url('./res/marker_map_icon.png');
  background-size: cover;
  width: 30px;
  height: 30px;
  cursor: pointer;
}










