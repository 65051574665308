.homeLaunch {
    background: url("../../../assets/res/mapbg.png");
    height: calc(1000*var(--xUnit));
    padding-top: calc(212*var(--xUnit));
    text-align: center;
  
  }
  
  .homeLaunch h1{
    font-family: 'babas_neue', 'normal';
    font-size: calc(80*var(--xUnit));
    color: var(--colorDeepRed);
    text-align: center;
  }
  
  .homeLaunch p{
    font-family: 'neobulletin';
    font-size: calc(30*var(--xUnit));
    text-align: center;
  }
  
  .homeLaunchPanel{
    /* display: flex; */
    justify-content: center;
    padding-top: calc(100*var(--xUnit));
    padding: 40rem 120rem;
    width: 80%;
    margin: 60rem auto;
  }
  
  
  .homeLaunchPanel img{
      display: block;
      width: 350rem;
      margin-right: auto;
      margin-left: auto;
  }
  .homeLaunchPanel a:hover {
    opacity: .6;
  }
  
  .homeLaunchPanel::-webkit-scrollbar {
    width: 10px;
  }

  @media (max-width: 768px) {
    .homeLaunch h1{
        font-size: calc(140*var(--xUnit));
      }

    .homeLaunch {
        height: calc(1200*var(--xUnit));
    
    }
  }