.back{
    font-family: var(--fontBabasNeue);
    /* margin: 1em; */
    color: var(--colorDarkBlue);
    display: flex;
    /* padding-left: 2em; */
    font-size: calc(16px + 1vw);
    width:fit-content;
}
.back:hover{
    cursor: pointer;
    /* color: var(--colorDeepRed); */
    color: #FFD048;
    ext-decoration-color: var(--colorDarkBlue);
    
}
/* .back p:hover{
    text-decoration: underline;
} */

.larr{
    margin-right: 1ch;
    /* background-color: #FAFAFA; */
    color: #141311;
    width: calc(50*var(--xUnit));
    height: calc(50*var(--xUnit));
    /* border-radius: calc(10*var(--xUnit)); */
    justify-content: center;
    display: flex;
    align-items: center;
    /* border: calc(2*var(--xUnit)) solid #F1F9FF; */
}