/*
BANNER
*/
a {
    text-decoration: none; /* no underline */
}


.whyBanner {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    align-items: center;
    background: url("../../assets/res/mapbg.png");
}

.whyBanner_button {
    width: 25%;
}

.whyBanner_card {
    width: 45%;
    border-left: 12rem solid var(--colorDeepRed);
    padding: 30rem;
    background-color: white;
    
}

.whyBanner_card h2 {
    font-family: var(--fontBabasNeue);
    font-size: 50rem;
    font-weight: normal;
    color: var(--colorDarkBlue);
    text-align: center;
}

.whyBanner_card p {
    font-size: 24rem;
    font-family: var(--fontNeoBulletin);
}

.why_readmore{
    color: black;
    margin-left: 600rem;
    margin-top: 50rem;
}
  
.why_readmore:hover {
    color: var(--colorDeepRed);
    cursor: pointer;
}

.whyBanner figure {
    position: relative;
    display: block;
    width: 30%;
    align-self: flex-end;
}

.whyBanner figure img {
    width: 100%;
}

.whyBanner figcaption {
    font-family: var(--fontBabasNeue);
    font-size: 32rem;
    color: var(--colorBeige);
    background-color: var(--colorDarkBlue);
    width: fit-content;
    padding: 8rem;
    margin-top: -56rem;
    position: relative;
}

/*
VIDEO
*/
.whyoutsideVideo {
    background-image: url("res/videobg.png");
    background-size: 100% 100%;
}

.whyVideo * {
    position: relative;
    display: block;
    width: fit-content;
    margin: auto;
    padding: 60rem;
}

/*
ESSAYS
*/

.whylowerSection {
    background-image: url("res/carouselbg.png");
    background-size: 100% 100%;
    padding-top: 150rem;
    padding-left: 60rem;
    padding-right: 60rem;
}

.whyEssays {
    background-color: var(--colorBeige);
    padding: 40rem 120rem 60rem 120rem;
    width: 80%;
    margin: 60rem auto;
}

.whyEssays h2 {
    font-family: var(--fontBabasNeue);
    font-size: 80rem;
    margin-bottom: 40rem;
    color: var(--colorDeepRed);
    font-weight: normal;
}

.whyEssays_list {
    display: flexbox;
    width: 100%;
    
    /* overflow-x: auto; */
    /* justify-content: left; */
}

.thumb_with_title {
    position: relative;
    text-align: center;
   
}

.thumb_with_title img{
    aspect-ratio:4/5;
}

.thumb_with_title_h3 {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: var(--fontBabasNeue);
    font-size: 40rem;
    color: var(--colorMediumBlue);
    font-weight: normal;
    width: 350rem;
    padding-right: 30.5rem;
    padding-left: 30.5rem;
}

.whyEssays_list img {
    display: block;
    width: 350rem;
    margin-right: auto;
    margin-left: auto;
}

.whyEssays_list a:hover {
    opacity: .6;
}

.whyEssays_list::-webkit-scrollbar {
    width: 10px;
}

/*
PUBLICATIONS
*/

.whyPublications {
    width: 50%;
    margin: auto;
}

.whyPublications h2 {
    font-family: var(--fontBabasNeue);
    font-size: 80rem;
    margin-bottom: 40rem;
    color: var(--colorDarkBlue);
    font-weight: normal;
}

.whyPublications_list {
    display: flex;
    width: 100%;
    padding-bottom: 80rem;
}

.whyPublications_list > * {
    width: 90%;
}

.whyPublications_list img {
    width: 90%;
    aspect-ratio: 8/11;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.whyPublications_list img:hover {
    opacity: .8;
    transform: scale(1.01);
}
