.captionedImg {
    font-size: calc(16px + 1vw);
    font-family: var(--fontBabasNeue);
    position: relative;
}

.captionedImg > img {
    width: 100%;
    vertical-align: bottom;
}

.captionedImg_caption {
    position: absolute; 
    height: 2em;
    color: var(--colorBeige);
    padding-left: 1em;
    padding-right: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--colorDarkBlue);
    font-size: .8em;
    bottom: 0;
}