.resources-banner{
	display: flex;
	flex-direction: row;
	align-items: center;

	gap:3em;

	img{
		flex:30%

	}

	div{
		display: flex;
		flex-direction: column;
		gap: 2em;
		flex:70%;
		h1{
			font-size: 5em;
			font-family: $fontBabasNeue;
			text-align: center;
			font-weight: 100;
		}
		div {
			display: flex;
			flex-direction: column;
			gap: 0.2em;
			
			div {
				width: 100%;
				height: 100%;
				border-width: 2.50px;
				border-style: solid;
			}
			
			div:nth-child(1) {
				border-color: #B32525;
			}
			
			div:nth-child(2) {
				border-color: #CB4E28;
			}
			
			div:nth-child(3) {
				border-color: #FFD048;
			}
		}
		p{
			font-family: $fontNeoBulletin;
			text-align: left;
			font-size: 1.5em;
		}
	}

}