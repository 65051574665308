.about{
  font-size: calc(16px + 1vw);
}

/*
BANNER
*/
.aboutBanner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: url("../../assets/res/mapbg.png");
}

/*
Control the individal space for each item in the banner
*/
.aboutBanner > img {
  width: 25%;
}

.aboutBanner > .bannercard {
  width: 50%;
  margin: 2em 0;
}

.aboutBanner > .captionedImg {
  width: 25%;
  margin-left: 4em;
}

.aboutHead > h1 {
  font-family: var(--fontBabasNeue);
  font-weight: normal;
  background-color: var(--colorBeige);
  width: fit-content;
  padding: .2em .4em;
  margin: .7em auto;
  text-align: center;
  border-left: .3em solid var(--colorDeepRed);
}

/*
TIMELINE
*/

.aboutTimeline {
  min-height: 1650rem;
}

.aboutTimeline_header {
  font-size: 80rem;
  font-family: var(--fontBabasNeue);
  text-align: center;
  margin-top: 106rem;
}

.aboutTimeline_annot {
  position: absolute;
}

.aboutTimeline_annot1 {
  /* margin-top: 1050rem; */
  margin-left: 180rem;
}

.aboutTimeline_annot2 {
  margin-left: 535rem;
  margin-top: 1050rem;
}

.aboutTimeline_annot3 {
  /* margin-top: 1050rem; */
  margin-left: 810rem;
}

.aboutTimeline_annot4 {
  margin-left: 1150rem;
  margin-top: 1050rem;
}

.aboutTimeline_annot5 {
  /* margin-top: 1050rem; */
  margin-left: 1450rem;
}

.aboutTimeline_annotNo {
  font-size: 200rem;
  font-family: var(--fontBabasNeue);
  color: rgba(117, 178, 224, .5);
  
  position: absolute;
}

.aboutTimeline_annotText {
  font-size: 16rem;
  font-family: var(--fontMontserrat);
  margin-top: 110rem;
  margin-left: 38rem;
  width: 234rem;
}

.aboutTimeline_tl {
  width: 1877rem;
  margin-left: 43rem;
  margin-top: 289rem;
}

/*
DOCUMENTS
*/

.aboutDocuments {
  margin-bottom: 200rem;
  align-items: top;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50rem;
}

.aboutDocuments a {
  font-size: 60rem;

  font-family: var(--fontBabasNeue);
  margin-bottom: 141rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 400rem;
  color: var(--colorDarkBlue);
  text-decoration: underline;
}

.aboutDocuments a:hover {
  color: #FFD048;
  text-decoration: underline;
  text-decoration-color: var(--colorDarkBlue);
}

/*
MEET
*/

.aboutMeet {
  margin-bottom: 0;
  margin-left: 40rem;
}

.aboutMeet img {
  width: 1750rem;
}

.aboutChart {
  margin-top: 64rem;
  margin-bottom: 64rem;
  margin-left: 170rem;
}

.aboutChart img{
  width: 1604rem;
  height: 980rem;
}

/*
TABLE
*/

.aboutTable {
  font-size: calc(16px + 1vw);
  display: flex;
  width: 90%;
  margin: 2em auto;
  border: 1px solid var(--colorDarkBlue);
  color: var(--colorDarkBlue);
}

.aboutTable_tabs {
  width: 30%;
}

.aboutTable_tab {
  font-family: var(--fontBabasNeue);
  width: 100%;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--colorDarkBlue);
  border: 1px solid var(--colorDarkBlue);
}

.aboutTable_tab:hover {
  background-color: var(--colorDarkBlue);
  color: white;
  cursor: pointer;
}

.aboutTable_tab--active {
  background-color: var(--colorDarkBlue);
  color: white;
  pointer-events: none;
}

.aboutTable_entries { 
  width: 70%;
  max-height: 20em;
  flex-direction: row;
  padding-top: 0.2em;
  overflow-y: scroll;
}

.aboutTable_entries > * {
  padding-left: 0.4em;
}

.aboutTable_entry {
  display: flex;
  align-items: center;
}

.aboutTable_steering_entry {
  padding: .5em;
  display: flex;
  align-items: center;
}

.aboutTable_steering_box {
  display: grid;
  padding-left: 1em;
}

.aboutTable_steering_entry > img {
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 999px;
  width: 4em;
}

.aboutTable_steering_name {
  font-family: var(--fontMontserrat);
  font-weight: 600;
  font-size: .7em;
  text-transform: uppercase;
  padding-bottom: 1em;
}

.aboutTable_steering_txt {
  font-size: .6em;
  font-family: var(--fontMontserrat);
  max-width: 70ch;
}

.aboutTable_name {
  font-family: var(--fontMontserrat);
  font-weight: bold;
  font-size: .6em;
}

.aboutTable_txt {
  font-family: var(--fontMontserrat);
  font-weight: normal;
  font-style: italic;
  max-width: 70ch;
}