/**
OSKRHQ.DS Default Theme
Hi! 👋🏼
Below, you will find the default theme for this Design System. 
However, you can modify the theme's styling values with your own
and what the UI change magically!
*/

/**
Color Palette Light
Change these color values below to your brand colors.
You can use `null` as a value if you do not have 
that color.
*/

// Brand - Primary

// Brand - Secondary

// Brand - Tertiary

//Neutral - Used for text, borders, shadows, background, etc
$color-beige:#FFF4E8;
// positive - Used to communicate status or feedback to the user

// Negative - Used to communicate status or feedback to the user

// Warning - Used to communicate status or feedback to the user

// Info - Used to communicate status or feedback to the user
$color-blue-deep:#142F45;
$color-blue-midium:#357A93;
$color-blue-light:#9EC7E1;
$color-blue-lighter:#F1F8FD;

$color-red-deep:#B32525;



// **TYPOGRAPHY**
// Typeface
// Change the typeface.
// Import whatever Font Family you would like.

@font-face {
	font-family: "babas_neue";
	// src: url("./assets/SS_Fonts/bebas_neue/BebasNeue-Regular.woff") format("woff");
    src:url("../../assets/SS_Fonts/bebas_neue/BebasNeue-Regular.woff") format("woff");
	font-weight: normal;
	font-size: normal;
}
@font-face {
	font-family: "gotham";
	src: url("../../assets/SS_Fonts/gotham_book/gotham_book_regular/gotham_book_regular.woff")
		format("woff");
	font-weight: normal;
	font-size: normal;
}
@font-face {
	font-family: "neobulletin";
	src: url("../../assets/SS_Fonts/neobulletin/neobulletin_limited_free_version.woff")
		format("woff");
	font-weight: normal;
	font-size: normal;
}
@font-face {
	font-family: "subway_ds";
	src: url("../../assets/SS_Fonts/subway_ds/subway_black.woff") format("woff");
	font-weight: normal;
	font-size: normal;
}
// @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
// .font-montserrat {
//   font-family: 'Montserrat', sans-serif;
// }
/* cyrillic-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw0aXx-p7K4KLjztg.woff) format('woff');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw9aXx-p7K4KLjztg.woff) format('woff');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw2aXx-p7K4KLjztg.woff) format('woff');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw3aXx-p7K4KLjztg.woff) format('woff');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw5aXx-p7K4KLg.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
// .font-montserrat-bold {
//   font-family: 'Montserrat', sans-serif;
// }
/* cyrillic-ext */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM73w0aXx-p7K4KLjztg.woff) format('woff');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM73w9aXx-p7K4KLjztg.woff) format('woff');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM73w2aXx-p7K4KLjztg.woff) format('woff');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM73w3aXx-p7K4KLjztg.woff) format('woff');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM73w5aXx-p7K4KLg.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  :root {  
    --fontBabasNeue: 'babas_neue';
    --fontGotham: 'gotham';
    --fontNeoBulletin: 'neobulletin';
    --fontSubwayDs: 'subway_ds', 'normal';
    --fontMontserrat: 'Montserrat', sans-serif;
  }

  $fontBabasNeue: 'babas_neue';
  $fontGotham: 'gotham';
  $fontNeoBulletin: 'neobulletin';
  $fontSubwayDs: 'subway_ds', 'normal';
  $fontMontserrat: 'Montserrat', sans-serif;
  