.slick-prev{
  left: -50px;
}

.slick-next{
  right: -50px;
}

.slick-next:before{
  font-family: '';
  opacity: 1;
  color: #FFF4E8;
 }

 .slick-prev:before{
  font-family: '';
  opacity: 1;
  color: #FFF4E8;
 }

 .slick-next:hover{
  opacity: .75;
 }

 .slick-prev:hover{
  opacity: .75;
 }
 .slick-dots li.slick-active button:before{
  color: #B32525;
  font-size: 15px;
 }
 .slick-dots {
  bottom: -20%;
 }

 .defaultItems img {
  max-width: 300px;
  aspect-ratio: 4 / 5;
}

:root {
  --arrow-size: calc(150*var(--xUnit));
}

@media (max-width: 768px) {
  .slick-dots {
    bottom: -50%;
  }

  :root {
    --arrow-size: calc(350*var(--xUnit)); /* Adjust this value based on your requirements */
  }
}
