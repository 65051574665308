.footer {
    display: grid;
    grid-template-columns: repeat(5, [col] auto ) ;
    grid-template-rows: repeat(2, [row] auto  );
    background-color: var(--colorDarkBlue);
    font-family: 'babas_neue';
    padding-top: 100rem;
    padding-left: 140rem;
    padding-right: 140rem;
    padding-bottom: 30rem;
}

.footer_top {
    font-size: 40rem;
    color: var(--colorLightBlue);
  }

  .icon {
    grid-column: 1 ;
    grid-row: 1;
    text-align: center;
    color: #3874A5;
    font-family: var(--fontMontserrat);
    font-size: 20rem;
    font-style: italic;
  }
  
  .home  {
    grid-column: 2 ;
    grid-row: 1;
    border-right: 2rem solid white;
  }
  
  .contact {
    grid-column: 3;
    grid-row: 1;
    border-right: 2rem solid white;
    margin-left: 80rem;
  }
  
  .donate {
    grid-column: 4;
    grid-row: 1;
    border-right: 2rem solid white;
    margin-left: 80rem;
    }
  
  .social {
    grid-column: 5;
    grid-row: 1;
    display: grid;
    grid-template-columns: repeat(3, [col] auto ) ;
    grid-template-rows: repeat(2, [row] auto  );;
    margin-left: 80rem;
  }
  
  .socialMedia {
    grid-column: col / span 3;
    grid-row: 1;
  }
  
  .instagram {
    grid-column: 1;
    grid-row: 2;
  }
  
  .twitter{
    grid-column: 2;
    grid-row: 2;
  }
  
  .facebook{
    grid-column: 3;
    grid-row: 2;
  }
  
  .home a,
  .contact a,
  .donate a {
    color: var(--colorLightBlue);
    text-decoration: none;
  }
  
  .home a:hover,
  .about a:hover,
  .contact a:hover,
  .donate a:hover{
    color: white;
  }
  
  .socialIcons a:hover img {
    transform: scale(1.04);
    padding-bottom: 12rem;
  }
  
  .footer_bot {
    grid-column: 1/6;
    grid-row: 2;
    margin-top: 40rem;
    font-size: 30rem;
    color: #3874A5;
    text-align: center;
  }
  
  .footer_support {
      margin-bottom: 10rem;
  }
  
  .footer_seal img {
    width: 400rem;
    margin-bottom: 10rem;
  }

  @media (max-width: 768px) {
    .footer {
        grid-template-columns: auto;
        grid-template-rows: repeat(6, auto);
        padding-left: 20rem;
        padding-right: 20rem;
    }

    .footer_top {
      margin-top: 50rem;
      font-size: 200rem;
      text-align: center
    }

    .home {
      grid-column: 1 ;
      grid-row: 1;
      border-right: none;
    }

    .contact {
      grid-column: 1;
      grid-row: 2;
      margin-left: 0;
      border-right: none;
    }

    .donate {
      grid-column: 1;
      grid-row: 3;
      margin-left: 0;
      border-right: none;
    }
    
    .social {
      margin-top: 200rem;
      grid-column: 1;
      grid-row: 4;
      display: grid;
      grid-column-gap: 0; /* Adjust the value as needed */
      margin-left: 0;
    }
    
    .instagram {
      grid-column: 1;
      grid-row: 2;
    }
    
    .twitter{
      grid-column: 2;
      grid-row: 2;
    }
    
    .facebook{
      grid-column: 3;
      grid-row: 2;
    }

    .icon {
      grid-column: 1 ;
      grid-row: 5;
      color: white;
      font-size: 40rem;
      margin-bottom: 50rem;
    }

    .icon img {
      width: 600rem;
    }
    
    .footer_bot {
      grid-column: auto;
      grid-row: auto;
      font-size: 90rem;
      color: white;
      margin-bottom: 100rem;
      padding-left: 50rem;
      padding-right: 50rem
    }

    .footer_seal img {
      width: 1200rem;
      margin-bottom: 40rem;
    }

    .footer_support {
      font-size: 90rem; 
      color: white;
      margin-bottom: 50rem;
      padding-left: 300rem;
      padding-right: 300rem;
      font-weight: normal;
    }
  
}