.homeMap_grayer {
  position: absolute;
  margin-top: calc(-1*180*var(--xUnit));
  width: 100vw;
  height: calc(1668*var(--xUnit));
  background-color: black;
  opacity: .69;
  z-index: 2;
}

.homeMap_map {
  width: calc(1612*var(--xUnit));
  height: calc(1067.6*var(--xUnit));
  background-color: var(--colorBeige);
  margin-left: calc(135.42*var(--xUnit));
  margin-top: calc(121.46*var(--xUnit));
  box-shadow: 0 calc(10*var(--xUnit)) calc(20*var(--xUnit)) #00000082;
  margin-bottom: calc(299*var(--xUnit));
}

.homeMap_poi {
  font-family: 'babas_neue';
  position: absolute;
  height: calc(1067.6*var(--xUnit));
  width: calc(420*var(--xUnit));
  background-color: var(--colorDarkBlue);
  margin-left: calc(79*var(--xUnit));
}

.homeMap_poiHeader {
  font-size: calc(72*var(--xUnit));
  color: #FFECD1;
  width: calc(307*var(--xUnit));
  margin-left: calc(30*var(--xUnit));
  margin-top: calc(51.6*var(--xUnit));
  margin-bottom: calc(38.76*var(--xUnit));
}

.homeMap_poiText {
  color: white;
  font-size: calc(40*var(--xUnit));
  margin-left: calc(31*var(--xUnit));
  margin-bottom: calc(14*var(--xUnit));
  width: calc(350*var(--xUnit));
}

.homeMap_poiText--hovering {
  transform: scale(1.01);
  cursor: pointer;
  color: var(--colorLightBlue);
}

.homeMap_interactive {
  margin-left: calc(498.14*var(--xUnit)); /*WAS 575.14*/
  height: fit-content;
  padding-top: calc(0.04*var(--xUnit));
}

.homeMap_img {
  position: absolute;
  width: calc(1113.94*var(--xUnit));
  height: calc(1067*var(--xUnit));
  border: 1px solid black;
}

.homeMap_dot {
  position: absolute;
}

.homeMap_dot img {
  width: calc(65*var(--xUnit));
  z-index: 0;
}

.homeMap_dot--hovering img {
  transform: scale(1.08);
  cursor: pointer;
}

.homeMap_dotLabel {
  display: none;
  pointer-events: none;
}

.homeMap_dot--hovering .homeMap_dotLabel {
  font-size: calc(20*var(--xUnit));
  display: block;
  font-family: 'neobulletin', 'normal';
  background-color: rgba(0, 0, 0, .69);
  color: white;
  padding: calc(10*var(--xUnit));
  border-radius: calc(40*var(--xUnit));
  margin-top: calc(-1*65*var(--xUnit));
  margin-left: calc(70*var(--xUnit));
  position: relative;
  z-index: 1;
}

/*
POPUP
*/

.homeMap_popup {
  position: absolute;
  z-index: 3;
  background-color: #615FBF;
  width: calc(700*var(--xUnit));
  height: calc(900*var(--xUnit));
  height: fit-content;
  border-radius: calc(75*var(--xUnit)) calc(75*var(--xUnit)) calc(75*var(--xUnit)) 0;
  margin-top: calc(-1*180*var(--xUnit));
  margin-left: calc(50*var(--xUnit));
  padding-bottom: calc(42*var(--xUnit));
  box-shadow: 0 calc(3*var(--xUnit)) calc(6*var(--xUnit)) #00000029;
}

.homeMap_popupTop {
  min-height: calc(565*var(--xUnit));
  height: fit-content;
  border-radius: calc(75*var(--xUnit)) calc(75*var(--xUnit)) calc(36*var(--xUnit)) 0;
  background-color: white;
  padding-top: calc(24*var(--xUnit));
  box-shadow: 0 calc(3*var(--xUnit)) calc(6*var(--xUnit)) #00000029;
}

.homeMap_popupBack {
  font-family: 'babas_neue', 'normal';
  display: flex;
  flex-direction:row;
  align-items: center;
  margin-left: calc(55*var(--xUnit));
  margin-right: calc(30*var(--xUnit));
  margin-bottom: calc(11*var(--xUnit));
}

.homeMap_popupBack p {
  font-size: calc(30*var(--xUnit));
  margin-left: calc(18*var(--xUnit));
  color: var(--colorLightGrayFont);
}

.homeMap_popupBack:hover {
  cursor: pointer;
}

.backArrow {
  /* background-color: #FAFAFA; */
  color: #141311;
  width: calc(50*var(--xUnit));
  height: calc(50*var(--xUnit));
  /* border-radius: calc(10*var(--xUnit)); */
  justify-content: center;
  display: flex;
  align-items: center;
  /* border: calc(2*var(--xUnit)) solid #F1F9FF; */
}

.homeMap_popupBack:hover .homeMap_popupBackArrow {
  transform: scale(1.08);
}

.homeMap_popupBack:hover p {
  color: var(--colorLightBlue);
}

.homeMap_popupImg img {
  margin-left: calc(83*var(--xUnit));
  border-radius: calc(15*var(--xUnit));
  width: calc(535*var(--xUnit));
  height: calc(324*var(--xUnit));
}

.homeMap_popupSrc {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  color: var(--colorLightGray);
  margin-right: auto;
  margin-top: calc(20*var(--xUnit));
  font-size: 20rem;
  text-align: left;
  width: 100%;

}

.homeMap_popupSrc p {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  font-size: 20rem;
  overflow-wrap: anywhere;
}

.homeMap_popupFeed {
  position: absolute;
  display: flex;
  padding-left: calc(48*var(--xUnit));
}

.homeMap_popupFeedLeft:hover, .homeMap_popupFeedRight:hover {
  width: calc(50*var(--xUnit));
  transform: scale(1.08);
  background-color: var(--colorLightBlue);
  cursor: pointer;
  border-radius: calc(999*var(--xUnit));
}

.homeMap_popupFeedImg img {
  border-radius: calc(15*var(--xUnit));
  width: calc(150*var(--xUnit));
  height: calc(107*var(--xUnit));
  margin: 25rem;
}

.homeMap_popupTitle {
  margin-top: calc(63*var(--xUnit));
  margin-left: calc(97*var(--xUnit));
  font-family: 'babas_neue', 'normal';
  font-size: calc(40*var(--xUnit));
  color: white;
}

.homeMap_popupLocation {
  font-family: 'babas_neue', 'normal';
  margin-left: calc(97*var(--xUnit));
  font-size: calc(40*var(--xUnit));
  color: white;
  margin-top: 80rem;
}

.homeMap_popupDescription {
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: calc(16*var(--xUnit));
  margin-top: calc(3*var(--xUnit));
  margin-left: calc(97*var(--xUnit));
  width: calc(560*var(--xUnit));
}

.close{
  color: var(--colorLightGrayFont);
  font-size: calc(20*var(--xUnit));
  /* text-align: center; */

}


.closeBorder{
  display:flex;
  margin-left:auto;
  justify-content:center;
  align-items:center;
  width: 1.5em;
  height: 1.5em;
  border:solid var(--colorLightGrayFont);
  border-radius: 50%;
  font-size: calc(30*var(--xUnit));
  text-align: center;
}

.closeBorder:hover{
  width: 1.5em;
  height: 1.5em;
  border:solid var(--colorLightBlue);
  border-radius: 50%;
  font-size: calc(30*var(--xUnit));

  
}
.close:hover{
  color: var(--colorLightBlue);
  font-size: calc(20*var(--xUnit));
}

/* MAP SOURCES */

.Map-Source {
  border-radius: 0px;
}

.Map-Source .toggle {
  font-size: calc(16*var(--xUnit));
  font-weight: bold;
  color: var(--colorWhite);
  text-align: left;
  padding-left: 0px; 
  position: relative; /* Added relative positioning */
}

.Map-Source .Map-Source-contents {
  font-size: 45rem;
  font-weight: normal;
  color: var(--colorWhite);
  margin-top: -40rem;
}

.Map-Source .Map-Source-contents p {
  font-family: var(--fontMontserrat);
  font-size: calc(16*var(--xUnit));
  color: var(--colorWhite);
  margin-bottom: 20rem;
  margin-top: 20rem;
}

.Map-Source-contents ul {
  padding-left: 40rem;
  font-size: calc(16*var(--xUnit));

}

.Map-Source .toggle::after {
  margin-left: 40rem;
  content: "\2303";
  font-size: calc(24*var(--xUnit));
  display: inline-block;
  transition: transform 0.3s;
  transform-origin: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -68%) rotate(180deg);
}

.Map-Source.active .toggle::after {
  transform: translate(-48%, -40%) rotate(0deg);
}
