.layout1 {
    margin: 60rem 0;
}

.layout1 p {
    font-family: var(--fontMontserrat);
    font-size: 24rem;
}

/*
BANNER
*/

.layout1Banner {
    width: 75%;
    margin: auto;
    background-color: var(--colorBeige);
    position: relative;
    min-height: 700rem;
    padding-top: 40rem;
}

.layout1Banner h1 {
    font-size: 90rem;
    font-family: var(--fontBabasNeue);
    font-weight: normal;
    text-align: center;
    color: var(--colorDarkBlue);
}

.layout1Banner_hr {
    height: 99rem;
    border-top: 33rem solid var(--colorDeepRed);
    background-color: var(--colorDeepOrange);
    border-bottom: 33rem solid var(--colorDeepYellow);
    margin-top: -250rem;
    position: relative;
    margin-bottom: 250rem;
    text-align: center;
}

.layout1Banner_hr_figure {
    width: 20%;
    margin-top: -200rem;
}

.layout1Banner_hr + figure figcaption {
    background-color: var(--colorDarkBlue);
    font-size: 48rem;
    font-family: var(--fontBabasNeue);
    color: var(--colorBeige);
    width: 100%;
    text-align: center;
    padding: 20rem;
    
    margin-bottom: calc(620rem + 60rem);
}

/*
P1
*/

.layout1P1 {
    padding: 60rem 40rem 60rem 50rem;
    display: flex;
}

.layout1P1_left {
    margin-left: 100rem;
    margin-right: 200rem;
}

.layout1P1_left figure {
    width: 300rem;
    box-shadow: 60px -48px var(--colorDeepYellow);
}

.layout1P1_left img{
    width: 100%;
    display: block;
}

.layout1P1_left figcaption {
    background-color: var(--colorDarkBlue);
    font-size: 26rem;
    font-family: var(--fontBabasNeue);
    color: var(--colorBeige);
    width: 100%;
    text-align: center;
    padding: 20rem;
}

.layout1P1_right {
    width: 40%;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
}

.layout1P1_right figure {
    margin-top: calc(-100% + 60rem);
    margin-bottom: 60rem;
}

.layout1P1_right img {
    width: 100%;
}

.layout1P1_right figcaption {
    background-color: var(--colorDarkBlue);
    font-size: 48rem;
    font-family: var(--fontBabasNeue);
    color: var(--colorBeige);
    width: 100%;
    text-align: center;
    padding: 20rem;
    margin-top: -30rem;
}

.layout1P2 {
    padding: 40rem 240rem;
}

.layout1P3 {
    display: flex;
    justify-content: space-between;
    padding: 60rem 120rem;
}

.layout1P3 p {
    width: 40%;
}

.layout1P3 figure {
    margin: 0;
    width: 700rem;
}
.layout1P3 img {
    display: block;
    width: 100%;
}

.layout1P3 figcaption {
    background-color: var(--colorDarkBlue);
    font-size: 26rem;
    font-family: var(--fontBabasNeue);
    color: var(--colorBeige);
    width: 100%;
    text-align: center;
    padding: 20rem;
}

.layout1_author p {
    padding: 60rem 120rem;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
}