.mappingNWC {
  font-size: calc(1vw + 16px);
  font-family: var(--fontBabasNeue);
}

/*
BANNER
*/
.mappingNWCBanner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: url("../../assets/res/mapbg.png");
}

/*
Control the individal space for each item in the banner
*/
.mappingNWCBanner > img {
  width: 25%;
}

.mappingNWCBanner > .bannercard {
  width: 40%;
  font-size: calc(1vw + 5px);
}

.mappingNWCBanner > .captionedImg {
  width: 25%;
  margin-left: 4em;
}

.marker {
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  }

/*
SEARCH
*/

.mappingNWCSearch {
  margin-right: 80rem;
  margin-top: 50rem;
  margin-left: 80rem;
  padding: .4em;
}

.mappingNWCSearch h1 {
  font-size:50rem;
  font-weight: normal;
  color: var(--colorDarkBlue);
  margin-bottom: 15rem;
}

.mappingNWCSearch hr {
  border: none;
  height: 4rem;
  color: var(--colorDarkBlue); /* old IE */
  background-color: var(--colorDarkBlue); /* Modern Browsers */
  margin-bottom: 10rem;
}

.mappingNWCSearch h2 {
  font-family: var(--fontMontserrat);
  font-size:25rem;
  color: var(--colorDarkBlue);

}

.mappingNWCSearch p {
  font-family: var(--fontMontserrat);
  font-size:25rem;
  color: var(--colorDarkBlue);
  margin-bottom: 20rem;
  margin-top: 20rem;
}

.mappingNWCSearchTemp{
  color: #000;
font-family: Montserrat;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 43px; /* 179.167% */
} 



.basicForm {
  font-size:25rem;
  color: var(--colorDarkBlue);
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: right;
}

.panel{
  flex-grow: 1;
  height: auto;
  font-family: var(--fontBabasNeue);
  padding-left: 1%;
  padding-right: 2%;
  padding-bottom: 10rem;
  border-bottom: 2rem dashed var(--colorDarkBlue);

}

.panel p{
  font-family: var(--fontBabasNeue);
  margin-top: 10rem;
  padding-bottom: 10rem;
  text-decoration: underline;
}

.panel + .panel {
  /*margin-left: 2%;*/
  border-left: 2rem dashed var(--colorDarkBlue);

}

.basicForm {
  /* border: 2rem dashed var(--colorDarkBlue); */
  background-color:rgba(158, 199, 225, 0.35);
  padding: 1%;
  border-radius: 1em;;  
}

.basic-multi-select {
  /* padding-bottom: 30rem; */
  margin-bottom: 30rem;
}

.form-control {
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.form-control + .form-control {
  margin-top: 1em;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

/* Need Fix, can have basic tage in this level */
button {
  font-size: 30rem;
  font-family: var(--fontBabasNeue);
  margin-top: 20rem;
  margin-bottom: 20rem;
  margin-right: 50rem;
  border: none;
  color: white;
  width: fit-content;
  padding-left: 50rem;
  padding-right: 50rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
  border-radius: 30rem;
  transition: color .5s, background-color .5s;
}

.searchButton {
  background-color: var(--colorDeepRed); 
  padding: 10rem 50rem;
  color: white;
  border-radius: 30rem;
  font-size: 30rem;
}

.resetButton {
  background-color: var(--colorDarkBlue);
  padding: 10rem 50rem;
  color: white;
  border-radius: 30rem;
  font-size: 30rem;
}

button:hover {
  text-decoration: underline;
}

.Result-Continer{
    margin: 50rem 80rem;
}
.Result-Continer p{
  color: #000;
font-family: Montserrat;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 43px; /* 179.167% */
}

.basicSearch_toggle{
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-end;
  align-items: center;
  margin-top: 2em;
  margin-right: 2em;
  gap: 1em;
  position: relative;
}
.basicSearch_toggle-left{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.basicSearch_toggle-container{
  position: relative;
}
.basicSearch_toggle-tooltip{
  position: absolute;
  top:0;
  left: 0;
  width: 210rem;
  background-color: #FFF4E8;
  color: black;
  padding: 0.25em;
  border-radius: 0.5em;
  font-size: 1.5em;
  display: none;
  border: 1px solid #000;
  z-index: 1;
}
.basicSearch_toggle-container:hover .basicSearch_toggle-tooltip{
  display: block;
}
.basicSearch_toggle-tooltip p{
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* .basicSearch_toggle:hover .basicSearch_toggle-tooltip{
  display: block;
} */

.basicSearch_toggle img{
  position: relative;
  top: -0.5em;
}

.basicSearchswitch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.basicSearchswitch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #60C1FF;
}

input:focus + .slider {
  box-shadow: 0 0 1px #60C1FF;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.basicSearch_footer{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  gap: 1em;
}
.basicSearch_footer-left{
  display: flex;
  
  flex:60%;
  flex-direction: row;
  
  align-items: center;
  gap: 1em;
}
.basicSearch_footer-left input{
  width: 35%;
  height: 100%;
  border: none;
  background: transparent;
	border-bottom: 0.1em solid var(--colorLightBlue);
  padding: 5px;
  font-size: 24px;
  font-family: var(--fontBabasNeue);
  font-weight: 500;
  color: var(--colorDarkBlue);
}
.basicSearch_footer-left input::placeholder{
  color: #939393;
font-family: Montserrat;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 43px; /* 179.167% */
}
.basicSearch_footer-left input:focus{
  outline: none;
}
.basicSearch_footer-right{
  display: flex;
  flex:30%;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

.progress-map {
  width: 100%;
  height: auto; /* Optional: Maintains aspect ratio */
}




