/*Little optional thing for scaling navbar on hover*/
/*
.navigation_total:hover .navigation {
  height: 100rem;
}

.navigation_total:hover .navigation_border {
  border-top: 20rem solid var(--colorDeepRed);
  border-bottom: 20rem solid var(--colorDeepOrange);
  margin-top: 100rem;
}

.navigation_total:hover .navigation .navigation_link {
  font-size: 40rem;
}
*/

.navigation {
  width: 100vw;
  height: 100rem;
  display: flex;
  background-color: var(--colorDarkBlue);
  align-items: center;
  position: fixed;
  z-index: 999;
  top: 0;
  box-shadow: 0 3rem 6rem #00000029;
}

.hamburger_icon {
  display: none;
  font-size: 35rem; /* Adjust as needed */
  cursor: pointer;
  position: absolute;
  right: 0; /* Adjust as needed */
  top: 0; /* Adjust as needed */
  transition: none;
  margin-right: 0;
}

.hamburger_icon_bg {
  background-color: var(--colorLightBlue);
  border-radius: 0;
  height: 318rem;
  margin-top: 0;
  
}

.navigation_search {
  width: 52rem;
  margin-right: 44.29rem;
}

.navigation_hamburger, .navigation_search {
  margin-bottom: 8rem;
}

.navigation_link {
  color: var(--colorLightBlue);
  font-family: 'babas_neue', normal;
  font-size: 35rem;
  margin-left: 110rem;
  text-decoration: none;
  white-space: nowrap;
}

.navigation_link--selected {
  color: white;
}

.navigation_link:hover {
  color: white;
  /* text-decoration: underline; */
}


.navigation_border {
  width: 100%;
  background-color: var(--colorDeepRed);
  border-top: 14rem solid var(--colorDeepRed);
  border-bottom: 14rem solid var(--colorDeepOrange);
  margin-top: 100rem;
}

/* Styles for larger screens */
.navigation_total .dove_icon {
  display: none; /* Hide dove icon */
}


@media (max-width: 768px) {
  .navigation_border {
    background-color: var(--colorDeepRed);
    border-top: 30rem solid var(--colorDeepRed);
    border-bottom: 30rem solid var(--colorDeepOrange);
    margin-top: 20rem;

  }

  .navigation {
    background-color: var(--colorDarkBlue); 
    box-shadow: none;

}
  .hamburger_icon {
    display: block;
  }
  
  /* Display the navigation when the menu is open */
  .navigation.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 320rem; 
    width: 100vw;
  }

  /* Adjust navigation links */
  .navigation_link {
    color: var(--colorDarkBlue);
    margin-left: 0;
    font-size: 130rem; 
    padding: 10px 0px;
    width: 100%;
    text-align: center;
  }
  
  .navigation_link--inactive {
    background-color: var(--colorDarkBlue); 
    color: var(--colorLightBlue); 
    margin: 0;
  }

  /* Styling for active links */
  .navigation_link--active {
    background-color: var(--colorLightBlue); 
    color: var(--colorDarkBlue); 
    margin: 0;
  }

  .navigation_total {
    background-color: var(--colorDarkBlue); 
  }

  .navigation_total .dove_icon {
    display: block;
    width: 330rem; 
    height: 300rem; 
    margin-left: 50rem;
}

  .dove_icon_container {
    display: inline-block;
    padding: 0;
    margin: 0;
  }
}