// TODO: Explanation of what this is...

@import './app.scss';

////////////////////////////////////////////////////////////////////////////
/// Connecting the Typographic styles defined above to Type-HTML elements
///////////////////////////////////////////////////////////////////////////

h1 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

h2 {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

h3 {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

h4 {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

h5 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

h6 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

ul,
ol {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

a {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-decoration: none;
}

button {
  cursor: pointer;
  color: #fff;
  border: none;
  font-family: babas_neue;

  &:focus {
    outline: 0;
  }
}

pre {
  margin: 2.5rem 0;
}

@mixin font-style($family, $size, $weight, $style) {
  font-family: $family;
  font-size: calc(#{$size} * 1vw);
  font-weight: $weight;
  font-style: $style;
}

$font-styles: (
  subway_ds:(
    title:(6, 400, normal),
  ),
  babas_neue:(
    heading-1:(4.5, 400, normal),
    heading-2:(2, 400, normal),
    heading-3:(1.5, 400, normal),
  ),
  Montserrat:(
    quote-1:(2.25, 700, normal),
    paragraph-2:(1.5, 400, normal),
    body-text:(1.25, 400, normal),
    caption:(1.5, 600, normal),
  ),
  neobulletin:(
    paragraph-1:(2, 400, normal),
  )
);

@each $family, $props in $font-styles {
  @each $class, $props in $props {
    .#{$class} {
      @include font-style($family, nth($props, 1), nth($props, 2), nth($props, 3));
    }
  }
}

