/*
SPLASH
*/

.homeSplash {
  display: flex;
  background: url('../../assets/res/mapbg.png');
  padding-left: 160rem;
  padding-top: 156.33rem;
  padding-bottom: 106.17rem;
}

.homeSplash_toForm img {
  width: 799.77rem;
}

.homeSplash_card {
  width: 696.16rem;
  height: 640.29rem;
  margin-left: 114rem;
  background-color: var(--colorLightBlue);
  color: white;
  text-align: center;
  padding-top: 19.45rem;
}

.homeSplash_card h3 {
  font-family: 'subway_ds';
  font-size: 96rem;
  width: 552rem;
  margin-left: 72rem;
}

.homeSplash_cardHr {
  width: 582.95rem;
  height: 6rem;
  background-color: #da4c4c;
  margin-left: 56.82rem;
  margin-bottom: 22rem;
  /* margin-top: 22rem; */
}

.homeSplash_card p {
  font-family: 'babas_neue';
  font-size: 65rem;
  margin-top: 41.47rem;
}


/*
MAP
*/

.homeMap_card {
  width: 1271.01rem;
  height: fit-content;
  min-height: 352.54rem;
  border: 10rem solid var(--colorDeepRed);
  margin-left: 324rem;
  margin-top: 260.5rem;
}

.homeMap_headerBackdrop {
  width: 599rem;
  height: 96rem;
  background-color: white;
  margin-left: 58.92rem;
  margin-top: -70rem;
}

.homeMap_header {
  margin-top: -116rem;
  margin-left: 42rem;
}

.homeMap_cardHr {
  background-color: var(--colorDeepRed);
  border-top: 15rem solid var(--colorDeepRed);
  border-bottom: calc(10 * var(--xUnit)) solid var(--colorDeepOrange);
  width: calc(563.29 * var(--xUnit));
  margin-left: calc(80.72 * var(--xUnit));
}

.homeMap_text {
  width: calc(1171.8 * var(--xUnit));
  margin-left: calc(32.01 * var(--xUnit));
  margin-top: calc(54.67 * var(--xUnit));
  margin-bottom: calc(25 * var(--xUnit));
}

.homeMap_opening {
  width: 80%;
  display: block;
  margin: auto;
}

.homeMap_tabs {
  margin-top: calc(122.46 * var(--xUnit));
  display: flex;
  margin-left: calc(102.5 * var(--xUnit));
  width: fit-content;
}

.homeMap_tabs div {
  width: calc(241 * var(--xUnit));
  height: calc(110 * var(--xUnit));
  margin-left: calc(33 * var(--xUnit));
  background-color: var(--colorDeepOrange);
  border-radius: calc(50 * var(--xUnit)) calc(50 * var(--xUnit)) 0 0;
  text-align: center;
  font-family: 'babas_neue', 'normal';
  font-size: calc(40 * var(--xUnit));
  color: var(--colorBeige);
  padding-top: calc(29 * var(--xUnit));
}

.homeMap_tabs div:hover {
  cursor: pointer;
  border-top: calc(4 * var(--xUnit)) solid #142f45;
  border-left: calc(4 * var(--xUnit)) solid #142f45;
  border-right: calc(4 * var(--xUnit)) solid #142f45;
  color: #142f45;
}

.homeMap_tabs .homeMap_tab--active {
  border-top: calc(4 * var(--xUnit)) solid #142f45;
  border-left: calc(4 * var(--xUnit)) solid #142f45;
  border-right: calc(4 * var(--xUnit)) solid #142f45;
  background-color: #ffffff;
  color: #142f45;
  pointer-events: none;
}

.homeMap_tabsHr {
  width: calc(1611.58 * var(--xUnit));
  height: calc(4 * var(--xUnit));
  background-color: #142f45;
  margin-left: calc(135.5 * var(--xUnit));
}

/*
THE CSS FOR HANDLING THE ACTUAL INTERACTIVE MAP CAN BE FOUND IN
./Map.css
*/

/*
EXPLORE
*/

.homeExplore {
  background-color: var(--colorBeige);
  padding-top: calc(162.26 * var(--xUnit));
}

.homeExplore_card {
  margin-left: auto;
  margin-right: auto;
  width: calc(830.47 * var(--xUnit));
  min-height: calc(329 * var(--xUnit));
  height: fit-content;
  border: calc(10 * var(--xUnit)) solid var(--colorDeepRed);
  padding-bottom: calc(34 * var(--xUnit));
}

.homeExplore_headerBackdrop {
  width: calc(610 * var(--xUnit));
  height: calc(96 * var(--xUnit));
  background-color: var(--colorBeige);
  margin-top: calc(-1 * 70 * var(--xUnit));
  margin-left: calc(110 * var(--xUnit));
}

.homeExplore_header {
  margin-top: calc(-1 * 116 * var(--xUnit));
  margin-left: calc(110 * var(--xUnit));
}

.homeExplore_hr {
  width: calc(551.82 * var(--xUnit));
  background-color: var(--colorDeepRed);
  margin-left: calc(139.01 * var(--xUnit));
  margin-top: calc(15.94 * var(--xUnit));
  border-top: calc(15 * var(--xUnit)) solid var(--colorDeepRed);
  border-bottom: calc(10 * var(--xUnit)) solid var(--colorDeepOrange);
}

.homeExplore_text {
  margin-top: calc(29.67 * var(--xUnit));
  width: calc(559 * var(--xUnit));
  margin-left: calc(142.01 * var(--xUnit));
}

.homeExplore_img {
  margin-top: calc(44.74 * var(--xUnit));
  margin-left: calc(-1 * 130 * var(--xUnit));
  height: calc(982 * var(--xUnit));
  overflow-y: hidden;
  overflow-x: hidden;
}

.homeExplore_img img {
  position: relative;
  left: 0;
  top: 0;
  width: calc(2155.6 * var(--xUnit));
}

.homeExplore_imgSrc {
  position: relative;
  width: calc(332 * var(--xUnit));
  height: calc(84 * var(--xUnit));
  background-color: var(--colorDarkBlue);
  color: var(--colorBeige);
  font-size: calc(36 * var(--xUnit));
  font-family: 'babas_neue', 'normal';
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(-1 * 84 * var(--xUnit));
}

.homeExplore_borderBot {
  height: calc(35 * var(--xUnit));
  width: 100vw;
  background-color: var(--colorDeepRed);
}

/*
BUTTONS
*/

.homeButtons {
  z-index: -1;
  height: 100%;

}

.homeButtons_button {
  display: flex;
  z-index: 1;
  background-color: white;
}

.homeButtons_button:hover {
  transform: scale(1.01);
}

.homeButtons_button img {
  width: calc(400 * var(--xUnit));
  height: calc(400 * var(--xUnit));
}

.homeButtons_button p {
  width: calc(420 * var(--xUnit));
  font-family: 'neobulletin', 'normal';
  color: var(--colorDarkBlue);
  font-size: calc(24 * var(--xUnit));
  font-weight: bold;
}

.homeButtons_button1 {
  margin-top: calc(157 * var(--xUnit));
  margin-left: calc(100 * var(--xUnit));
  
}

.homeButtons_button2 {
  margin-top: calc(157 * var(--xUnit));
  margin-left: calc(1800 * var(--xUnit));
}

.homeButtons_button3 {
  margin-top: calc(720 * var(--xUnit));
  margin-left: calc(100 * var(--xUnit));
}

.homeButtons_button4 {
  margin-top: calc(720 * var(--xUnit));
  margin-left: calc(1800 * var(--xUnit));
}

.homeButtons_dots {
  position: absolute;
  width: calc(420 * var(--xUnit));
  height: calc(420 * var(--xUnit));
  z-index: 1
}

.homeButtons_dots1 {
  margin-top: calc(-1000 * var(--xUnit));
  margin-left: calc(15 * var(--xUnit));
}

.homeButtons_dots2 {
  right: 0;
  margin-top: calc(-1000 * var(--xUnit));
  margin-right: calc(15 * var(--xUnit));
}

.homeButtons_dots3 {
  margin-top: calc(-450 * var(--xUnit));
  margin-left: calc(16 * var(--xUnit));
}

.homeButtons_dots4 {
  margin-top: calc(-450 * var(--xUnit));
  margin-right: calc(16 * var(--xUnit));
  right: 0;
}


/* Default settings for the scroll-to-top button */
.scroll-to-top {
  display: none; /* Hide the button by default */
}

/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
  .scroll-to-top {
    display: block; /* Show the button for smaller screens */
    z-index: 1;
    position: fixed;
    right: 0%;
    bottom: 5%; /* Adjust the distance from the bottom as a percentage */
    background-color: #B22524;
    min-width: 40px; /* Set a minimum width for the button */
    min-height: 40px; /* Set a minimum height for the button */
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .arrow-up {
    position: relative; /* Required for ::before pseudo-element */
    transform: rotate(270deg);
  }

  .arrow-up::before {
    content: ''; /* Required for pseudo-element */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 10px;
    height: 10px;
    border-top: 2px solid white;
    border-right: 2px solid white;
  }
  
  .homeButtons_dots1 {
    margin-top: calc(-7000 * var(--xUnit));
    margin-left: calc(15 * var(--xUnit));
  }
  
  .homeButtons_dots2 {
    right: 0;
    margin-top: calc(-7000 * var(--xUnit));
    margin-right: calc(15 * var(--xUnit));
  }
  
}

/*
THE CSS FOR HANDLING THE ACTUAL INTERACTIVE MAP CAN BE FOUND IN
./HighlightsCarousel.css
*/