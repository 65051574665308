.layout3 {
    padding-top: 120rem;
    padding-left: 200rem;
    padding-right: 200rem;
}

.layout3 p {
    margin-block-start: 20rem;
    margin-block-end: 20rem;
    font-size: 25rem;
    color: var(--colorDarkBlue);
    font-family: var(--fontMontserrat);
}

.layout3 h1 {
    font-size: 80rem;
    font-family: var(--fontBabasNeue);
    color: var(--colorDarkBlue);
    font-weight: normal;
    text-align: center;
    margin-bottom: 120rem;
}

.layout3 h2 {
    color: var(--colorDarkBlue);
    font-size: 40rem;
    font-family: var(--fontBabasNeue);
    font-weight: normal;
    margin-bottom: 20rem;
    margin-top: 20rem;
}

.layout3 img {
    display: block;
    margin: auto;
    margin-bottom: 60rem;
    max-width: 100%;
    height: 50%;
    width: auto;
}

.layout3_quote {
    color: var(--colorDarkBlue) !important;
    width: 70%;
    border-left: 8rem solid var(--colorDeepRed);
    max-width: 70ch;
    padding: 20rem;
    font-weight: bold;
    font-style: italic;
    margin: auto;
}

.layout3_section1 {
    columns: 2;
    column-gap: 8%;
    break-inside: auto;
    padding-bottom: 80rem;
}

.layout3_section2 {
    padding-bottom: 80rem;
}

.layout3_section3 {
    display: flex;
    padding-bottom: 80rem;
}

.layout3_section3Text {
    width: 50%;
}

.layout3_section3Quote {
    width: 40%;
    height: 10%;
    color: var(--colorDarkBlue) !important;
    border-right: 8rem solid var(--colorDeepRed);
    padding: 20rem;
    font-style: italic;
    font-weight: bold;
    margin: auto;
    margin-bottom: 120rem; 
}

.layout3_author p {
    color: var(--colorDarkBlue);
    font-size: 30rem;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
}

.layout3_sources h2 {
    font-size: 40rem;
    font-family: var(--fontBabasNeue);
    font-weight: normal;
    margin-top: 120rem;
    margin-bottom: 40rem;
}

.layout3_sources p {
    font-size: 15rem;
    margin-bottom: 20rem;
    color: var(--colorDarkBlue);
}

.layout3_prefCit {
    color: var(--colorDarkBlue);
    font-family: var(--fontMontserrat);
    padding-bottom: 120rem;
    font-size: 20rem;
}

.layout3_prefCit h2 {
    font-size: 40rem;
    font-family: var(--fontBabasNeue);
    color: var(--colorDarkBlue);
    font-weight: normal;
    margin-top: 120rem;
    margin-bottom: 40rem;
}


