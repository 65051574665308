.participants {
    font-size: calc(16px + 1vw);
    font-family: var(--fontBabasNeue);
    padding: 1em 0;
    color: var(--colorDarkBlue);  
}

.participants a {
    color: var(--colorDeepRed);
}

.participants a:hover {
    text-decoration: underline;
}

.participants > h1 {
    text-align: center;
    margin-bottom: 1em;
}

.backToDiscover{
    padding-left: 2em;

}
/* .participantsOptions {
    display: flex;
    padding-right: 2em;
    padding-left: 2em;
    flex-direction: row;
    justify-content: space-between;
    
}

.participantsFilter {
    display: flex;
}

#select{
    padding-left: 2em;
}

.participantsDownloadButton {
    float: right;
}

.participantsList {
    padding: 0em 2em 1em 2em;
    margin: auto;
    height: 20em;
    overflow-y: auto;
}

.participantContainer{
    columns: 3;
    background-color: rgba(117, 178, 224, .3);
    padding: .5em;
}

.participantContainer > ul{
    display: flex;
    flex-flow: column wrap;
    font-family: var(--fontMontserrat);
    font-size: .5em;

}

.alphabetList {
    padding: 0em 3em .5em 2em;
    padding-left: 2em;
    padding-right: 3.0em;
    display:flex;
    gap:.3em;
    
}

.alphabetList p{
    color: rgba(117, 178, 224, .3);
}

.activeLetter{
    color:var(--colorDarkBlue); 
    border: 1px solid black;
}

.letterSort:hover {
    
    cursor: pointer;
    opacity: .7;
    transform: scale(1.04);
}

.resetLetterSort{
    margin-left:auto;
}

.resetLetterSort:hover {
    cursor: pointer;
    opacity: .7;
    transform: scale(1.04);
} */