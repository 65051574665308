.meetIcon {
    font-size: calc(16px + 1vw);
    text-align: center;
    width: fit-content;
}

.meetIcon:hover {
    cursor: pointer;
}

.meetIcon > img {
    max-height: 12em;
    aspect-ratio: 1/1;
}

.meetIcon_name {
    font-family: var(--fontBabasNeue);
    font-size: 2em;
}

.meetIcon_role {
    font-family: var(--fontMontserrat);
    max-width: 15ch;
    margin: auto;
}

.meetIcon_popup {
    display: flex;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    position: absolute;
    left:20%;
    background-color: white;
    margin-top: -14em;
    margin-right: 4em;
}

.meetIcon_popup > img{
    width:400px;
    height:auto;
}
.popup_img{
    width:100%;
    height:auto;
}


#close {
    
    /* right: .5em; */
  
    font-family: var(--fontBabasNeue);
    font-weight: normal;
    color: white;
    /* padding-left:.5em;
    padding-top:.2em; */
    font-size: .5em;
    
}

.closeBorderIcon{
    display:flex;
    /* position: relative;
    float: right; */
    justify-content:center;
    align-items:center;
    margin-left:auto;
    border: solid white;
    border-radius: 50%;
    width: 1.1em;
    height: 1.1em;
    text-align:center;
}

.meetIcon_popupHeader {
    background-color: var(--colorDarkBlue);
    color: white;
    text-align: left;
    padding-left: 1ch;
    padding-right: 1ch;
    padding-top: .5em;
    padding-bottom: .5em;
    
}

.topLine{
    display:flex;
    /* align-items:center; */
}

.topLine > h3 {
    font-family: var(--fontBabasNeue);
    font-weight: normal;
    max-width: 20em;
    
}

.meetIcon_popupHeader > p {
    font-family: var(--fontMontserrat);
    font-size: .6em;
}

.meetIcon_popupText > p {
    font-family: var(--fontMontserrat);
    font-size: .6em;
    text-align: left;
    padding: .8em;
    max-width: 900px;
    max-height: 400px;
    white-space: normal;
    overflow: auto;
}