.pdf {
  font-size: calc(1vw + 12px);
}

.pdf__back {
  font-family: var(--fontBabasNeue);
  margin: 1em;
  color: var(--colorDarkBlue);
  display: flex;
  
}

.pdf__larr {
  margin-right: 1ch;
}

.pdf__back:hover {
  cursor: pointer;
  color: var(--colorDeepYellow);
  text-decoration-color: var(--colorDarkBlue);
}

/* For pdf */
.pdf__document {
  width: fit-content;
  margin: 4em auto;
  padding: 2em 1.5em 2em;
  background-color: var(--colorBackgroundLightGray);
  
}
.pdf__page {
  padding: 10px;
  
}

/* .react-pdf__Page__canvas {
  margin: 0 auto;
  
  border: solid black;
  
} */

.downloadButton{
  background-color: var(--colorDeepRed);
  color:white;
  border-radius: 8px;
  font-family: var(--fontBabasNeue);
  font-size: 0.7em;
  width: fit-content;
  margin: 2em auto;
  
}

.downloadButton:hover{
  background-color: white;
  color: var(--colorDeepRed);
  border: 2px solid var(--colorDeepRed);
  cursor: pointer;
}

.dwButton{
  display: grid;
  justify-content: flex-end;
  padding-right: 10px;
}

.backButton{
  padding: 1em 0em 0em 2em;
}