
.torch p {
    margin-block-start: 20rem;
    margin-block-end: 20rem;
    font-size: 25rem;
    color: var(--colorDarkBlue);
    font-family: var(--fontMontserrat);
}
/*
BANNER
*/
.torchSection1 {
    font-family: var(--fontMontserrat);
    padding-bottom: 120rem;
    padding-left: 200rem;
    padding-right: 200rem;
}

/*
SECTION 1
*/

.torchSection1_title h1 {
    font-size: 80rem;
    font-family: var(--fontBabasNeue);
    color: var(--colorDarkBlue);
    font-weight: normal;
    text-align: center;
    max-width: 40ch;
    margin: auto;
    padding-top: 120rem;
    padding-bottom: 120rem;
}

.torchSection1 {
    display: flex;
    align-items: center;
}

.torchSection1 > * {
    width: 60%;
}

.torchSection1 img {
    width: 80%;
}

.torchSection1 figcaption {
    width: 80%;
    font-size: 20rem;
    font-family: var(--fontMontserrat);
    text-align: right;
    padding: 20rem;
}

.torchSection1_text p {
    color: var(--colorDarkBlue);
}

/*
MIDPHOTO
*/
.torchMidphoto {
    width: 100%;
    position: relative;
}

.torchMidphoto img {
    width: 100%;
}

.torchMidphoto figcaption {
    font-size: 35rem;
    font-family: var(--fontBabasNeue);
    color: var(--colorBeige);
    background-color: var(--colorDarkBlue);
    width: fit-content;
    padding: 20rem;
    position: absolute;
    bottom: 0;
    right: 0;
}

.torch_border {
    width: 100vw;
    border-top: 25rem solid var(--colorDeepRed); /*high*/
    height: 75rem; /*mid*/
    background-color: var(--colorDeepOrange);
    border-bottom: 25rem solid var(--colorDeepYellow);/*low*/
  }

/*
SECTION 2
*/

.torchSection2 {
    display: flex;
    font-family: var(--fontMontserrat);
    padding-bottom: 120rem;
    padding-left: 200rem;
    padding-right: 200rem;
}

.torchSection2 > * {
    width: 50%;
}

.torchSection2 img {
    width: 100%;
    margin-top: 100rem;
}

.torchSection2_title {
    font-size: 40rem;
    font-family: var(--fontMontserrat);
    color: var(--colorDarkBlue);
    font-weight: normal;
    margin-top: 120rem;
    margin-bottom: 40rem;
}

.torchSection2_quote {
    margin-left: 10rem;
    margin-bottom: 120rem;
}

.torchSection2_quote div{
    border-left: 8rem solid var(--colorDeepRed);
    font-size: 25rem;
    padding: 40rem;
    font-family: var(--fontMontserrat);
    color: var(--colorDarkBlue);
    font-weight: bold;
    font-style: italic;
    margin: auto;
}

.torchSection2_right {
    margin-left: 120rem;
}

.torchSection2_right figcaption {
    font-size: 20rem;
    font-family: var(--fontMontserrat);
    text-align: right;
    padding: 20rem;
}

/*
MAP
*/

.torchMap {
    background-color: var(--colorDeepRed);
    font-family: var(--fontMontserrat);
    font-size: 25rem;
    margin-bottom: 40rem;
    padding: 40rem;
}

.torchMap h2 {
    text-align: center;
    color: white;
    padding: 40rem;
    font-weight: normal;
    font-size: 45rem;
}

.torchMap figure {
    display: block;
    margin: auto;
    width: 80%;
}

.torchMap img {
    width: 100%;
    margin: auto;
}

.torchMap figcaption {
    width: 100%;
    font-family: var(--fontMontserrat);
    color: white;
    text-align: right;
}

/*
SECTION 3
*/

.torchSection3 {
    display: flex;
    font-family: var(--fontMontserrat);
    padding-left: 200rem;
    padding-right: 200rem;
}

.torchSection3 > * {
    width: 50%;
}

.torchSection3_left h2 {
    font-size: 40rem;
    font-family: var(--fontMontserrat);
    color: var(--colorDarkBlue);
    font-weight: normal;
    margin-top: 120rem;
    margin-bottom: 40rem;
}

.torchSection3 img {
    padding-left: 120rem;
    width: 100%;
}

.torchSection3 figcaption {
    font-size: 20rem;
    font-family: var(--fontMontserrat);
    text-align: right;
    padding: 20rem;
}

/*
CONCLUSION
*/

.torchConclusion {
    font-family: var(--fontMontserrat);
    padding-left: 200rem;
    padding-right: 200rem;
    color: var(--colorDarkBlue);
    padding-bottom: 40rem;
}

.torchConclusion h2 {
    font-size: 40rem;
    font-family: var(--fontMontserrat);
    color: var(--colorDarkBlue);
    font-weight: normal;
    margin-top: 120rem;
    margin-bottom: 40rem;
}

.torch_author p{
    color: var(--colorDarkBlue);
    padding-left: 200rem;
    padding-right: 200rem;
    font-size: 30rem;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
}

/*
SOURCES
*/

.torchSources {
    padding-left: 200rem;
    padding-right: 200rem;
    
}

.torchSources h2 {
    font-size: 40rem;
    font-family: var(--fontBabasNeue);
    font-weight: normal;
    margin-top: 120rem;
    margin-bottom: 40rem;
}

.torchSources p {
    font-size: 15rem;
    margin-bottom: 20rem;
    color: var(--colorDarkBlue);
}

.torch_prefCit {
    color: var(--colorDarkBlue);
    font-family: var(--fontMontserrat);
    padding-bottom: 120rem;
    padding-left: 200rem;
    padding-right: 200rem;
    font-size: 20rem;
}

.torch_prefCit h2 {
    font-size: 40rem;
    font-family: var(--fontBabasNeue);
    color: var(--colorDarkBlue);
    font-weight: normal;
    margin-top: 120rem;
    margin-bottom: 40rem;
}



