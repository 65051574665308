.Card{
    img{
        max-width: 100%;
        height: auto;
    }

    p{
        font-family: $fontBabasNeue;
        text-align: center;
        font-size: 2em;
    }
}