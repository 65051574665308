.meet {
    font-size: calc(16px + 1vw);
}

/*
HEAD
*/

.meetHead > h1 {
    font-family: var(--fontBabasNeue);
    font-weight: normal;
    background-color: var(--colorBeige);
    width: fit-content;
    padding: .2em .4em;
    margin: .7em auto;
    text-align: center;
    border-left: .3em solid var(--colorDeepRed);
}

.meetHead > h2 {
    font-family: var(--fontBabasNeue);
    font-weight: normal;
    width: fit-content;
    margin: auto;
    font-size: .9em;

}

/*
LEADS
*/

.meetLeads {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.meetLeads > * {
    margin: 1em;
}