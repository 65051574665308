.organizations {
    font-size: calc(16px + 1vw);
    font-family: var(--fontBabasNeue);
    padding: 1em 0;
    color: var(--colorDarkBlue);  
}

.organizations a {
    color: var(--colorDeepRed);
}

.organizations a:hover {
    text-decoration: underline;
}

.organizations > h1 {
    text-align: center;
    margin-bottom: 1em;
}

.backToDiscover{
    padding-left: 2em;

}