.forms {
  font-size: calc(12px + 1vw);
  font-family: var(--fontBabasNeue);
  margin: 1em;
}

/*
CORRECTIONS
*/
.corrections {
  margin: 2em;
  width: fit-content;
  margin: auto;
  padding: 2em 1em;
  background-color: #ebf6fc;
}

.corrections_heading {
  text-align: center;
  margin-bottom: 1em;
  font-weight: normal;
}

.corrections_p {
  font-family: var(--fontMontserrat);
  font-size: 0.6em;
  max-width: 60ch;
  text-align: center;
  margin: 2em auto;
}

.corrections_preq {
  font-family: var(--fontMontserrat);
  font-size: 0.6em;
  max-width: 60ch;
  text-align: center;
  margin: 2em auto;
  font-style: italic;
}

.corrections :is(input, textarea) {
  width: 100%;
  max-width: 140ch;
  display: block;
  margin: 1em;
  text-indent: 1ch;
  padding: 0.4em;
  border-radius: 8px;
}

.corrections_submit {
  margin: 2em auto !important;
  width: fit-content !important;
  padding: 0.5em 3em !important;
  display: block;
  border: none;
  background-color: var(--colorDeepRed);
  color: white;
  font-family: var(--fontBabasNeue);
  font-size: 0.7em;
  border-radius: 32px;
  transition: background-color 0.2s, color 0.2s, border 0.2s;
  text-indent: 0 !important;
}

.corrections_submit:hover {
  background-color: white;
  color: var(--colorDeepRed);
  border: 2px solid var(--colorDeepRed);
  cursor: pointer;
}

.corrections_validate {
  color: #bf1650;
  margin: 1em;
  font-size: 16px;
}

.corrections_ph ::placeholder{
  opacity: .5;
}

/* FORMS */

.forms_p {
  font-family: var(--fontMontserrat);
  font-size: 0.6em;
  margin: 0.9em;

}