.overlay_vid {
  background-color: black;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

.overlay_vid video {
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

.overlay_vid video:hover {
  cursor: pointer;
}

.overlay_vid--off {
  display: none;
}

.overlay_vid_continue {
  position: absolute;
  width: calc(400*var(--xUnit));
  height: calc(70*var(--xUnit));
  text-align: center;
  background-color: var(--colorLightBlue);
  font-family: 'babas_neue';
  font-size: calc(40*var(--xUnit));
  border: calc(6*var(--xUnit)) double black;

  margin-top: calc(-1*300*var(--xUnit));
  margin-left: calc(1200*var(--xUnit));
  z-index: 1;
}

.overlay_vid_continue:hover {
  transform: scale(1.01, 1.01);
  border: calc(6*var(--xUnit)) double white;
  color: white;
  cursor: pointer;
}






.overlay_vid_card {
  width: calc(745*var(--xUnit));
  height: calc(720*var(--xUnit));
  margin-top: calc(-1*800*var(--xUnit));
  margin-left: calc(200*var(--xUnit));
  position: absolute;
  z-index: 1;
  background-color: var(--colorDarkBlue);
  opacity: 70%;
  color: white;
  text-align: center;
}

.overlay_vid_card h3 {
  font-family: 'subway_ds';
  font-size: calc(96*var(--xUnit));
  margin-top: calc(40*var(--xUnit));
}

.overlay_vid_cardhr {
  width: calc(664*var(--xUnit));
  height: calc(6*var(--xUnit));
  margin-left: calc(49.5*var(--xUnit));
  background-color: var(--colorDeepRed);
  margin-top: calc(40*var(--xUnit));
}

.overlay_vid_card p {
  font-family: 'babas_neue';
  font-size: calc(65*var(--xUnit));
  margin-top: calc(40*var(--xUnit));
}

.overlay_vid_cardbut {
  background-color: white;
  color: black;
  padding-right: calc(20*var(--xUnit));
  padding-left: calc(20*var(--xUnit));
  width: 335rem;
  margin-left: 210rem;
  border-radius: calc(40*var(--xUnit));
  border-bottom-left-radius: 0;
  margin-top: calc(40*var(--xUnit));
  font-size: calc(40*var(--xUnit));
  font-family: var(--fontMontserrat);
  height: 71rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay_vid_cardbut:hover {
  cursor: pointer;
  transform: scale(1.01, 1.01);
  opacity: 100%;
  color: white;
  background-color: var(--colorDarkBlue);
}

.overlay_vid_skip {
  position: absolute;
  font-size: 40rem;
  font-family: var(--fontSubwayDs);
  color: white;
  width: fit-content;
  z-index: 999;
  cursor: pointer;
  top: 20%;
  right: 10%;
}

.overlay_vid_skip:hover {
  text-decoration: underline;
  transform: scale(1.01);
}