.discoverCard {
    font-size: calc(1vw + 16px);
    font-family: var(--fontBabasNeue);
    border: 4px solid yellow;
    border-radius: 24px;
    position: relative;
    text-decoration: none;
}

.discoverCard:hover {
    transform: scale(1.01);
    cursor: pointer;
    opacity: .95;
}

.discoverCard_holePunch {
    width: 2.5em;
    height: .5em;
    border: 4px solid yellow;
    position: absolute;
    left: 50%;
    top: .3em;
    transform: translateX(-50%);
    border-radius: 999px;
}

.discoverCard_items {
    display: flex;
    margin-top: 1.3em;
}

.discoverCard_logo {
    margin-left: 4%;
}
.discoverCard_logoPic{
    box-shadow: 8px 4px 5px rgb(223, 221, 221);
}

.discoverCard_logoPic{
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
}

.discoverCard_data {
    margin: auto 4%;
    width: 100%;
}

.discoverCard_data p{
    border: 4px solid yellow;
    border-top: none;
    border-left: none;
    font-size: .6em;
    padding-top: .4em;
    color: rgb(0, 0, 0);
}

.discoverCard_data .discoverCard_name {
    border-top: 4px solid yellow;
}

.discoverCard_bottom {
    margin-bottom: 1rem;
    text-align: center;
    font-family: var(--fontMontserrat);
    font-size: .65em;
    color: black;
}