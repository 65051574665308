.homeAbout_beigeBackdrop {
    width: 1610rem;
    height: 950rem;
    background-color: var(--colorBeige);
    margin-top: 112rem;
    margin-left: 122rem;
  }
  
  .homeAbout_content {
    display: flex;
    margin-top: -1062rem;
  }
  
  .homeAbout_card {
    width: 835rem;
    min-height: 730rem;
    height: fit-content;
    border: 10rem solid var(--colorDeepRed);
    margin-left: 46.78rem;
    margin-top: 230rem;
    padding-bottom: 56rem;
    margin-bottom: 20rem;
  }
  
  .homeAbout_playnice {
    width: 835rem;
  }
  
  .homeAbout_headerBackdrop {
    background-color: var(--colorBeige);
    height: 96rem;
    width: 654rem;
    margin-left: 109.22rem;
    margin-top: -62rem;
    overflow-y: hidden;
  }
  
  .homeAbout_header {
    font-family: 'babas_neue', 'normal';
    font-size: 96rem;
    margin-left: 68rem;
    margin-top: -96rem;
  }
  
  .homeAbout_cardHr {
    width: 198.36rem;
    background-color: var(--colorDeepRed);
    border-top: 15rem solid var(--colorDeepRed);
    border-bottom: 10rem solid var(--colorDeepOrange);
    margin-left: 138.22rem;
    margin-top: -4rem;
  }
  
  .homeAbout_p1 p {
    margin-bottom: 36rem;
  }
  
  .homeAbout_peas {
    overflow-y: auto;
    overflow-x: hidden;
    height: 500rem;
  }
  
  .homeAbout_p1 {
    margin-top: 76.5rem;
    padding-right: 142.78rem;
  }
  
  .homeAbout_p2 {
    margin-top: 36rem;
    padding-right: 91.78rem;
  }
  
  .homeAbout_p1,
  .homeAbout_p2 {
    
    font-family: 'neobulletin', 'normal';
    font-weight: bold;
    font-size: 24rem;
    width: 835rem;
    padding-left: 143rem;
  }
  
  .homeAbout_readmore {
    font-size: 45rem;
    font-family: var(--fontBabasNeue);
    margin-left: 600rem;
    margin-top: 50rem;
  }
  
  .homeAbout_readmore:hover {
    color: var(--colorDeepRed);
    cursor: pointer;
  }
  
  .homeAbout_chicks {
    margin-left: 38.2rem;
    margin-bottom: 0;
  }
  
  .homeAbout_chicks img {
    width: 1114rem;
  }
  
  .homeAbout_imgCred {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'babas_neue', 'normal';
    font-size: 36rem;
    width: fit-content;
    min-width: 323rem;
    height: 84rem;
    background-color: var(--colorDarkBlue);
    margin-left: 500rem;
    color: var(--colorBeige);
    margin-top: -89rem;
    position: relative;
    text-align: right;
    padding: 12rem;
  }
  
  .homeAbout_border {
    width: 100vw;
    border-top: 25rem solid var(--colorDeepRed); /*high*/
    height: 75rem; /*mid*/
    background-color: var(--colorDeepOrange);
    border-bottom: 25rem solid var(--colorDeepYellow); /*low*/
  }

/* Media query for smaller screens */
@media screen and (max-width: 768px) {

  .homeAbout_beigeBackdrop {
    height: 2900rem;
    width: 88%;
  }

  .homeAbout_card {
    width: 235rem;
    border: 30rem solid var(--colorDeepRed);
  }

  .homeAbout_content {
    flex-direction: column; /* Change to column layout */
    align-items: center; /* Align items horizontally */
  }
  
  .homeAbout_card {
    width: 80%; /* Occupy full width of the parent container */
    margin-top: -1700rem;
  }
  
  .homeAbout_chicks {
    margin-top: -20rem; /* Adjust as needed */
  }

  .homeAbout_header {
    font-size: 8vw;
    margin-left: 150rem;
    margin-top: -136rem;
  }
  
  .homeAbout_p1,
  .homeAbout_p2 {
    font-size: 2.5vw;
    width: 100%;    
  }

  .homeAbout_headerBackdrop {
    width: 1054rem;
    margin-left: 260rem;
    overflow-y: hidden;
  }

  .homeAbout_peas {
    overflow-y: auto;
    overflow-x: hidden;
    height: 1100rem;
  }

  .homeAbout_readmore {
    font-size: 6vw;
    margin-left: 1000rem;
  }

  .homeAbout_cardHr {
    width: 900rem;
    margin-left: 350rem;
  }

  .homeAbout_imgCred {
    font-size: 2vw;
    margin-left: 900rem;
  }
  
  .homeAbout_border {
    border-top: 65rem solid var(--colorDeepRed); /*high*/
    height: 200rem; /*mid*/
    border-bottom: 65rem solid var(--colorDeepYellow); /*low*/
  }
}