.corrections_submit {
    margin: 2em auto !important;
    width: fit-content !important;
    padding: 0.5em 3em !important;
    display: block;
    border: none;
    background-color: var(--colorDeepRed);
    color: white;
    font-family: var(--fontBabasNeue);
    font-size: 0.7em;
    border-radius: 8px;
    transition: background-color 0.2s, color 0.2s, border 0.2s;
    text-indent: 0 !important;
  }

  .corrections_submit:hover {
    background-color: white;
    color: var(--colorDeepRed);
    border: 2px solid var(--colorDeepRed);
    cursor: pointer;
  }