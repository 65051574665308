.discover {
    font-size: calc(1vw + 16px);
    font-family: var(--fontBabasNeue);
}

/*
BANNER
*/
.discoverBanner {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: url("../../assets/res/mapbg.png");
}

/*
Control the individal space for each item in the banner
*/
.discoverBanner > img {
    width: 25%;
}

.discoverBanner > .bannercard {
    width: 45%;
    margin: 2em 0;
}

.discoverBanner > .captionedImg {
    width: 30%;
}

/*
FEATURED
*/

.discoverFeatured > h2 {
    font-weight: normal;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

.discoverFeatured_cards {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1em;
    margin-left: 1em;
    margin-right: 1em;
    flex-wrap: wrap;
    position: relative;
}

.discoverFeatured_cards > * {
    margin: 1em 0;
    width: 30%;
    position: relative;
    border-radius: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/*
INTRO
*/
.discoverIntro {
    padding-left: 100rem;
    padding-right: 100rem;
    padding-bottom: 20rem;
    padding-top: 100rem;
} 
.discoverIntro > p {
    font-family: var(--fontMontserrat);
    font-size:25rem;
    color: var(--colorDarkBlue);
}

/*
SEARCH
*/

.discoverSearch {
    width: calc(100% - 5em);
    margin: auto;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--colorLightBlue);
    padding: .4em;
    align-items: center;
}

.discoverSearch_bar {
    width: fit-content;
    display: flex;
    padding: 0.2em .2em;
}

.discoverSearch_bar > input {
    border: none;
    outline: none;
    border-bottom: .1em solid var(--colorLightBlue);
    height: fit-content;
    font-size: .9em;
    margin: auto;
    padding-left: 1ch;
    margin-right: 1ch;
    /* width: 19vw; */
}

.discoverSearch_bar > input::placeholder {
    color: lightgray;
    font-size: .8em;
}

.discoverSearch_bar > input:focus {
    border-bottom: .1em solid var(--colorDeepRed);
}

.discoverSearch_icon {
    background-image: url("./res/searchicon.png");
    background-repeat: no-repeat;
    border-radius: 0;
    background-size: 30%;
    cursor: pointer;
    margin: 0;
}

.discoverSearch_icon:hover {
    cursor: pointer;
    color: var(--colorDeepRed);
    transform: scale(1.04);
}

.discoverSearch_sortBy {
    display: flex;
    font-size: .9em;
    align-items:center;
}

.discoverSearch_sortBy > * {
    margin: 0 .5em;
    color: var(--colorLightBlue);
}

.discoverSearch_sorter:hover {
    cursor: pointer;
    opacity: .7;
    transform: scale(1.04);
}

.discoverSearch_separater {
    font-family: 'Courier New', Courier, monospace;
}

.discoverButtons {
    width: calc(100% - 2em);
    margin: .5em auto;
    display: flex;
    justify-content: left;
    margin-bottom: 50rem;
}

.discoverButtons_participants {
    color: white;
    background-color: var(--colorDarkBlue);
    width: fit-content;
    padding: .2em;
    border-radius: 12px;
    transition: color .5s, background-color .5s;
}

.discoverButtons_participants:hover {
    background-color: white;
    color: var(--colorDarkBlue);
    text-decoration: underline;
}

/*
CARDS
*/

.discoverCards_border {
    width: 96%;
    height: 1.5em;
    border-top: .5em solid var(--colorDeepRed);
    background-color: var(--colorDeepOrange);
    border-bottom: .5em solid var(--colorDeepYellow);
    margin: 1em auto;
}

.discoverCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.discoverCards > * {
    margin: 1em 0;
    width: 30%;
}

/*
PREV/NEXT
*/

.cardsPerPage {
    width: calc(100% - 5em);
    display: flex;
    margin: auto;
    padding: .4em 0em .4em .4em;
}

.cardsPerPageHeader{
    margin-top:15rem;
    color: var(--colorLightBlue);
    font-size: 1.3em;
}

.numberOfCards{
    font-family: var(--fontBabasNeue);
    background-color:var(--colorLightBlue);
}

.activeNumberOfCards{
    background-color:var(--colorDarkBlue);
}

.activeSortMethod{
    border-radius:30rem;
    padding:5rem 25rem 5rem 25rem;
    background-color:var(--colorDarkBlue);
    color:white;
}

/*
PAGINATION COMPONENET
*/

.paginationContainer {
    text-align: center;
    font-family: var(--fontBabasNeue);
}

.pagination {
    position: absolute;
    display: flex;
    list-style: none;
    cursor: pointer;
    margin-left: 220rem;
    margin-top: 15px;
    margin-bottom: 10px;
    left: 50%;
}

.pagination a {
    display: inline-block;
    padding: 8px;
    margin: 1px;
    color: var(--colorLightBlue);
}

.pagination__link {
    font-weight: normal;
}

.pagination__link--active a {
    color: rgba(0, 0, 0, 0.616);
    background: var(--colorLightBlue);
}

.pagination__link--disabled a {
    color: rgb(198, 197, 202);
}

.resetButton{
    margin-left:auto;
    background-color: var(--colorLightBlue);
    margin-right:0;
}


.cardsListPerPage button{
    font-size: 30rem;
    font-family: var(--fontBabasNeue);
    margin-top: 20rem;
    margin-bottom: 20rem;
    margin-right: 50rem;
    border: none;
    color: #fff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10rem 50rem;
    border-radius: 30rem;
    transition: color .5s,background-color .5s;
}